<template>
    <b-container fluid>
       <b-row>
          <b-col sm="12">
               <card>
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Student Details</h4>
                  </template>
                  <template v-slot:headerAction>
                     <div class="float-right details">
                        <router-link :to="{ name: 'students' }" class="btn btn-primary mt-2 mr-1 btn-sm"><i class="fas fa-angle-double-left"></i> Back</router-link>
                     </div>
                  </template>
                  <template v-slot:body>
                     <div class="details">  
                        <b-row>

                           <b-col md="6" lg="4" xl="3">
                              <h6>Enrollment No:</h6>
                              <p>{{ student.enrollment_number }}</p>
                           </b-col>

                           <b-col md="6" lg="4" xl="3">
                              <h6>Name:</h6>
                              <p>{{ student.name }}</p>
                           </b-col>                         
                           
                           <b-col md="6" lg="4" xl="3">
                              <h6>D.O.B:</h6>
                              <p>{{ student.date_of_birth }}</p>
                           </b-col>

                           <b-col md="6" lg="4" xl="3">
                              <h6>Phone No:</h6>
                              <p>{{ student.mobile }}</p>
                           </b-col> 

                           <b-col md="6" lg="4" xl="3">
                              <h6>Email:</h6>
                              <p>{{ student.email }}</p>
                           </b-col> 

                           <b-col md="6" lg="4" xl="3">
                              <h6>Father's Name:</h6>
                              <p>{{ student.father_name }}</p>
                           </b-col> 

                           <b-col md="6" lg="4" xl="3">
                              <h6>Mother's Name:</h6>
                              <p>{{ student.mother_name }}</p>
                           </b-col> 

                           <b-col md="6" lg="4" xl="3">
                              <h6>Created on:</h6>
                              <p>{{student.created_at | formatDateTime}}</p>
                           </b-col>    
                           
                           <b-col md="6" lg="4" xl="3">
                              <h6>State:</h6>
                              <p>{{ student.state }}</p>
                           </b-col>

                           <b-col md="6" lg="4" xl="3">
                              <h6>District:</h6>
                              <p>{{ student.district }}</p>
                           </b-col>

                           <b-col md="6" lg="4" xl="3">
                              <h6>Course:</h6>
                              <p>{{ student.course }}</p>
                           </b-col>

                           <b-col md="6" lg="4" xl="3">
                              <h6>Session:</h6>
                              <p>{{ student.start }}-{{ student.end }}</p>
                           </b-col>

                           <b-col md="6" lg="4" xl="3">
                              <h6>Branch:</h6>
                              <p>{{ student.branch }}</p>
                           </b-col>

                           <b-col md="6" lg="4" xl="3">
                              <h6>Sub branch:</h6>
                              <p>{{ student.subbranch }}</p>
                           </b-col>

                           <b-col md="6" lg="4" xl="3">
                              <h6>Department:</h6>
                              <p>{{ student.department }}</p>
                           </b-col>

                           <b-col md="6" lg="4" xl="3">
                              <h6>Centre:</h6>
                              <p>{{ student.centre }}</p>
                           </b-col>
                           <b-col md="6" lg="4" xl="3">
                              <h6>Gender:</h6>
                              <p>{{ student.student_gender }}</p>
                           </b-col>
                           <b-col md="6" lg="4" xl="3">
                              <h6>Address:</h6>
                              <p>{{ student.address }}</p>
                           </b-col>
                        </b-row>
                     </div>

                  </template>
               </card>

               <card>
                  <template v-slot:headerTitle>
                     <h6 class="card-title">Files</h6>
                  </template>
                  <template v-slot:headerAction>
                     
                  </template>
                  <template v-slot:body v-if="student.file_status == 1">
                     <div class="details"> 
                        <b-row  v-for="file in files" :key="file.id">  
                           <b-col md="6">
                              <h6>File Name:</h6>
                              <p>{{ file.file_name }}</p>
                           </b-col>

                           <b-col md="6">
                              <h6>File:</h6>
                              <p><a :href="$image_url + file.file" target="_blank"><i class="fas fa-file-pdf" style="font-size: 25px;"></i></a></p>
                           </b-col>                           
                          
                        </b-row>
                     </div>
                  </template>
                  <template v-slot:body v-else>
                     No files uploaded
                  </template>
               </card>

               <card>
                  <template v-slot:headerTitle>
                     <h6 class="card-title">Payments</h6>
                  </template>
                  <template v-slot:headerAction>
                     
                  </template>
                  <template v-slot:body v-if="student.payment_status == 1"> 
                     <div class="details">  
                        <b-row v-for="payment in payments" :key="payment.id">
                           <b-col md="6" lg="4" xl="4">
                              <h6>Title</h6>
                              <p>{{payment.title}}</p>
                           </b-col>

                           <b-col md="6" lg="4" xl="4">
                              <h6>Payment Received</h6>
                              <p>Rs. {{payment.payment_received}}</p>
                           </b-col>

                           <b-col md="6" lg="4" xl="4">
                              <h6>Notes</h6>
                              <p>{{ payment.notes }}</p>
                           </b-col>
                           
                        </b-row>
                     </div>
                  </template>  
                  <template v-slot:body v-else>
                     No payment records found
                  </template>  
               </card>

          </b-col>
       </b-row>
    </b-container>
</template>
<script>
export default {
   name:'StudentDetails',
   data() {
      return {
         student: {}, 
         files: [],
         payments: [],                 
         student_status: {
            id: "",
            status: "",
         },  

         token: '',
         errors: {}
      };
   },
   mounted () {
      
   },

   created() {
      this.fetchStudent();
   },

   methods: {
      
      fetchStudent() {
          let url = '/api/students/details?student_id=' + this.$route.params.id ;
            
         this.getToken();
         this.$axios
            .get(url, {headers: {Authorization: 'Bearer ' + this.token,}})
            .then(res => {
               var responseData = res.data.data;               
               this.student = responseData.student; 
               this.files   = responseData.files;
               this.payments= responseData.payments;
               console.log(this.student);             
            })
            .catch(err => console.log(err));
      },   

      getToken() {
         if(localStorage.token) {
            this.token = localStorage.token;
         } else {
            this.$vToastify.error('Session expired, please login again');
            this.$router.push({name: 'auth.login'})
         }
      },      
   }   
}
</script>